import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  if (isAuthenticated) {
    enqueueSnackbar('Login Successfull', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      variant: 'success'
    });
    return (
      <Redirect
        to={`${
          user
            ? user.user_group === 'owner'
              ? '/app/fee/dashboard'
              : user.user_group === 'library'
              ? '/app/LibraryCRM/Library/Library_crm_view/crm/Library':
              user.user_group === 'library_device'
              ? '/app/CRM/device/Library/check_in_out_device_view/Device/Library'
              : user.user_group === 'admin'
              ? '/app/fee/dashboard'
              : '/app/fee/dashboard'
            : '/app/fee/dashboard'
        }`}
      />
    );
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
